import React, { memo } from 'react'
import Timeline from '@mui/lab/Timeline'
import { TimelineItem as MUITimelineItem } from '@mui/lab'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import sx from './styles'
import TimelineItem from '../TimelineItem'

const VerticalTimeline = ({
  data, tabs = [], showLinks = true, ...props
}) => {
  return (
    <Timeline {...props}>
      {data?.map((item, i) => (
        <MUITimelineItem
          key={`MUITimelineItem-${i}`}
          sx={sx.timelineItem}
        >
          <TimelineSeparator>
            {item.dotIcon ? <item.dotIcon /> : <TimelineDot sx={sx.timelineDot} />}
            {data.length > i + 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent sx={sx.timelineContent}>
            <TimelineItem
              item={item}
              sx={{ mb: i >= data.length - 1 ? 0 : 3, mt: -1 }}
              tabs={item?.displayModalTabs ? tabs : null}
              showLinks={showLinks}
            />
          </TimelineContent>
        </MUITimelineItem>
      ))}
    </Timeline>
  )
}
export default memo(VerticalTimeline)
