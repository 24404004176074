const styles = {
  title: {
    marginBottom: 1,
    marginTop: 1,
    '&.MuiTypography-body1': {
      // fontSize: '16px',
      // lineHeight: '14px',
      // fontWeight: '600',
      color: 'text.primary',
    },
  },

  subtitle: {
    marginBottom: 1,
  },

  modal: {
    '& .MuiDialogContent-root': {
      paddingX: 2,
    },
    '& .MuiTimeline-root': {
      marginBottom: 0,
    },
    '& .MuiTabPanel-root': {
      marginTop: 4,
    },
  },
  timelineRoot: {
    padding: 0,
  },
  ratingContainer: {
    marginTop: 1,
    padding: 1,
    backgroundColor: 'background.grey450',
    borderRadius: 0.5,
    '& .MuiBox-root:first-of-type': {
      border: 0,
    },
  },
  disclaimer: {
    backgroundColor: 'background.grey450',
    padding: 1,
  },
}

export default styles
