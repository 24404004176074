import React, { memo } from 'react'
import CircleIcon from '@mui/icons-material/Circle'
import Divider from '@mui/material/Divider'
import sx from './styles'

const TimelineIconItem = ({ showDivider }) => {
  return (
    <>
      <CircleIcon sx={sx.icon} />
      {showDivider && (
        <Divider
          orientation="horizontal"
          sx={sx.divider}
        />
      )}
    </>
  )
}

export default memo(TimelineIconItem)
