import React, { memo } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import HorizontalTimeLine from './HorizontalTimeline'
import VerticalTimeline from './VerticalTimeline'
import sx from './styles'

const Timeline = (props) => {
  const { variant = 'horizontal' } = props
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  if (variant === 'horizontal' && !isMobile) return <HorizontalTimeLine {...props} />

  return (
    <VerticalTimeline
      sx={sx.root}
      {...props}
    />
  )
}
export default memo(Timeline)
