import React, { memo } from 'react'
import Grid from '@mui/material/Grid'
import size from 'lodash/size'
import TimelineIconItem from './TimelineIconItem'
import TimelineItem from '../TimelineItem'
import sx from './styles'

const HorizontalTimeline = (props) => {
  const {
    data = [],
    sx: sxProps = {},
    tabs = [],
    showLinks,
  } = props

  const itemSize = size(data) > 3 ? 3 : 4

  const containerStyles = {
    ...sx.container,
    ...sxProps,
  }

  return (
    <Grid
      item
      xs={12}
      sx={containerStyles}
      container
    >
      <Grid
        item
        xs={12}
        container
      >
        {data.map((_, index) => (
          <Grid
            item
            xs={itemSize}
            key={`data-step-${index}`}
            sx={sx.timelineIconContainer}
          >
            <TimelineIconItem
              showDivider={index !== size(data) - 1}
            />
          </Grid>
        ))}
      </Grid>
      <Grid
        item
        container
        xs={12}
      >
        {data.map((item, i) => (
          <Grid
            item
            xs={itemSize}
            sx={sx.timelineItemContainer}
            key={`data-text-${i}`}
          >
            <TimelineItem
              item={item}
              key={item.title}
              tabs={item?.displayModalTabs ? tabs : null}
              showLinks={showLinks}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default memo(HorizontalTimeline)
