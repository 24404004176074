const styles = {
  container: {
    padding: 2,
    px: { xs: 0 },
    borderRadius: 1,
  },

  footerText: {
    px: 1,
    mt: { xs: 0, md: 1 },
  },

  timelineIconContainer: {
    position: 'relative',
    marginTop: 1,
  },

  timelineItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}

export default styles
